<template>
  <div class="chat-container">
    <div class="chat-header">
      <h2>{{ headerTitle }}</h2>
      <span v-if="userType === 'MANAGER'" class="close" @click="closeModal">&times;</span>
      <div class="chat-actions" v-if="userType === 'MANAGER'">
        <button @click="bargeCall" v-if="!isListening" class="barge-button">Barge</button>
        <button @click="toggleMute" v-if="isListening">{{ isMuted ? 'Unmute' : 'Mute' }}</button>
        <!-- <button @click="toggleMute" v-if="isListening && isMuted" class="unmute-button">Unmute</button>
        <button @click="toggleMute" v-if="isListening && !isMuted" class="unmute-button">Mute</button> -->
        <audio id="manager-audio" autoplay></audio>
      </div>
    </div>
    <div class="chat-messages" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index" :class="['message', getMessageClass(message)]">
        <span class="sender">{{ getMessageSender(message) }}</span>
        <span>{{ message.text }} - {{ formatChatTimestamp(message.time) }}</span>
      </div>
    </div>
    <div class="chat-input">
      <input type="text" v-model="messageInput" placeholder="Type your message..." @keyup.enter="sendMessage" />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>
<script>
import io from 'socket.io-client';
import axios from 'axios';
import { TelnyxRTC } from '@telnyx/webrtc';
import SocketService from './socketService.js';

export default {
  props: {
    type: String,
    salesRepId: String,
    managerId: String,
    headerTitle: {
      type: String,
      default: 'Chat'
    },
    phoneNumber: {
      type: String
    },
    callId: {
      type: String
    }
  },
  data() {
    return {
      messageInput: '',
      messages: [],
      socket: null,
      userId: this.$route.query.id,
      userType: this.type, // either 'REP' or 'MANAGER',
      telnyx: null,
      currentCall: null,
      isMuted: true,
      username: 'russell32925',
      password: 'gbtKZ5ym',
      callerId: '+19492884866',
      usernameTwo: 'cmharrisiii48614',
      passwordTwo: 'T0Do3WIN',
      callerIdTwo: '+13125300209',
      telnyxRTC: null,
      localAudioTrack: null,
      isListening: false
    };
  },
  mounted() {

   this.socket = SocketService.connect();

   console.log('socket: ', this.socket);

    console.log("userType: ", this.userType);
    if (this.userType === 'REP') {
      console.log('chatSalesRepId: ', this.salesRepId);
      SocketService.register(this.salesRepId);
    } else if (this.userType === 'MANAGER') {
      SocketService.register(this.managerId);
    }
    SocketService.on('new_message', this.handleNewMessage);

    console.log('this.salesRepId: ', this.salesRepId);
    console.log('this.managerId: ', this.managerId);

    this.fetchMessages();
    if(this.userType === 'MANAGER') {
      this.markMessagesAsRead(this.salesRepId, this.managerId);
      //this.connect();
    }
  },
  methods: {
    async closeModal() {
      if(this.currentCall) {
        await this.currentCall.hangup();
      }
      this.$emit('close-modal');
    },
    connect() {
      let id = this.$route.query.id;
      let managerNum = '';
      let user = '';
      let pass = '';
      let callerIdNum = '';
      if (id == '0d0a642f-3e81-47cc-917b-b5c5c70c5a48') {
        managerNum = '+19492884866';
        user = this.username;
        pass = this.password;
        callerIdNum = this.callerId;
      }
      if (id == '3a67b80-1a3f-4c8f-972f-2133ff03fb2d') {
        managerNum = '+13125300209';
        user = this.usernameTwo;
        pass = this.passwordTwo;
        callerIdNum = this.callerIdTwo;
      }
      managerNum = '+19492884866';
        user = this.username;
        pass = this.password;
        callerIdNum = this.callerId;
      try {
        this.telnyxRTC = new TelnyxRTC({
          login: user,
          password: pass,
          callerIdNumber: callerIdNum,
          debug: this.enableDebugging,
          logLeveL: 'debug',
        });
      } catch (err) {
        console.error("error init listening", err)
      }
      this.telnyxRTC.on('telnyx.socket.open', () => {
        console.log('Socket opened');
      });

      this.telnyxRTC.on('telnyx.socket.error', (error) => {
        console.error('Socket error', error);
      });

      this.telnyxRTC.on('telnyx.socket.close', () => {
        console.log('Socket closed');
      });

      this.telnyxRTC.on('telnyx.ready', () => {
        console.log('Telnyx ready');
      });

      this.telnyxRTC.on('telnyx.error', (error) => {
        console.error('Telnyx error', error);
      });

      this.telnyxRTC.on('telnyx.call.incoming', (call) => {
        // this.call = call;
        console.log('Incoming call', call);
      });

      this.telnyxRTC.on('telnyx.call.answered', (call) => {
        console.log('Call answered', call);
        //this.attachStreamToAudioElement(this.call.remoteStream);
      });

      this.telnyxRTC.on('telnyx.call.hangup', (call) => {
        console.log('Call hung up', call);
      });

      this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
        console.log('call: ', call);
      });

      this.telnyxRTC.on('telnyx.notification', async (notification) => {
        console.log('telnyxNotification: ', notification);
        try {
          const call = notification.call;

          console.log('call: ', call.tt);

          if (notification.type === 'callUpdate' && call.state === 'ringing') {
            if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
              call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
            ) {
              // call.answer();
              // if(this.call) {
              //   this.mergeCalls(this.call, call);
              // }
            }
          }
          if (notification.type === 'callUpdate') {
          if (notification.call.state === 'active') {
            try {
              this.listeningCall = notification.call;
              this.localAudioTrack = this.listeningCall.localStream.getAudioTracks()[0];
              this.localAudioTrack.enabled = false;
              this.attachStreamToAudioElement(notification.call.remoteStream);
            } catch (err) {
              console.error('error attaching stream to audio element: ', err);
            }
          }
        }
        } catch (err) {
          console.log("Error answering call: ", err);
        }
      });

      this.telnyxRTC.connect();
    },
    toggleMute() {
      console.log('toggleMute: ', this.isMuted);
      this.isMuted = !this.isMuted;
      if(this.localAudioTrack) {
        this.localAudioTrack.enabled = !this.localAudioTrack.enabled;
      }
      console.log('isMuted: ', this.isMuted);
    },
    makeCall(fromNum, toNum) {
      console.log("fromNum: ", fromNum);
      console.log("toNum: ", toNum);
      if (this.telnyxRTC) {
        if (toNum.length === 10) {
          toNum = '+1' + toNum;
        }
        fromNum = '+19492884866';
        this.currentCall = this.telnyxRTC.newCall({
          destinationNumber: fromNum,
          callerNumber: fromNum,
        });
        this.isListening = true;
      }
    },
    attachStreamToAudioElement(stream) {
      const audioElement = document.getElementById('manager-audio');
      audioElement.srcObject = stream;
      console.log('audioElementAttached: ', audioElement);
    },
    connectAudio() {
      if (this.currentCall) {
        this.currentCall.options.audio = true;
        this.muteCall();
      }
    },
    muteCall() {
      if (this.currentCall) {
        this.currentCall.muteAudio();
        this.isMuted = true;
      }
    },
    unmuteCall() {
      if (this.currentCall) {
        this.currentCall.unmuteAudio();
        this.isMuted = false;
      }
    },
    // toggleMute() {
    //   if (this.isMuted) {
    //     this.unmuteCall();
    //   } else {
    //     this.muteCall();
    //   }
    // },
    detachAudioElement() {
      const audioElement = document.getElementById('manager-audio');
      if (audioElement) {
        if (audioElement.srcObject) {
          // Get the tracks of the media stream
          const tracks = audioElement.srcObject.getTracks();

          // Stop each track
          tracks.forEach(track => track.stop());
        }

        audioElement.srcObject = null;  // Clear the stream
        audioElement.pause();           // Pause the audio element
      }
    },
    bargeCall() {
      this.connect();
      let id = this.$route.query.id;
      axios.get(`https://123avatars.com/v2/barge-call?id=${this.salesRepId}&managerId=${this.managerId}&callId=${this.callId}`)
        .then((resp) => {
          if(resp.data.message) {
            alert(resp.data.message);
          } else {
            this.makeCall(this.callerIdTwo, this.phoneNumber);
          }
        }).catch((err) => {
          console.error("error barging call: ", err);
        });
      console.log('id: ', id);
    },
    unmuteCall() {

    },
    getTimezone() {
      // Get the local timezone
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      console.log('timezone: ', localTimezone);

      // List of US timezones
      const usTimezones = [
        'America/New_York',    // EST/EDT
        'America/Chicago',     // CST/CDT
        'America/Denver',      // MST/MDT
        'America/Phoenix',     // MST (no DST)
        'America/Los_Angeles', // PST/PDT
        'America/Anchorage',   // AKST/AKDT
        'America/Adak',        // HST/HDT
        'Pacific/Honolulu',    // HST (no DST)
      ];

      // Check if the local timezone is a US-based timezone
      if (usTimezones.includes(localTimezone)) {
        return localTimezone;
      }

      // If not a US timezone, return PST/PDT
      return 'America/Los_Angeles';
    },
    formatChatTimestamp(timestamp) {
      const timezone = this.getTimezone();
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone
      };

      const date = new Date(timestamp);
      return date.toLocaleString('en-US', options);
    },
    async markMessagesAsRead(salesRepId, managerId) {
      try {
        const response = await axios.post('https://123avatars.com/v2/markMessagesAsRead', {
          sales_rep_id: salesRepId,
          manager_id: managerId
        });

        if (response.status === 200) {
          console.log('Messages marked as read successfully:', response.data.message);
          // Handle success, e.g., update UI to reflect that messages have been read
        } else if (response.status === 404) {
          console.log('No unread messages found:', response.data.message);
          // Handle case where there were no unread messages
        }
      } catch (error) {
        console.error('Error marking messages as read:', error.response?.data?.error || error.message);
        // Handle error, e.g., display an error message to the user
      }
    },
    emitEvent() {
      this.$emit('new_message', 'new_message');
    },
    handleNewMessage(message) {
      console.log('message: ', message);
      this.messages.push(message);
      this.$nextTick(this.scrollToBottom);
      this.emitEvent();
    },
    fetchMessages() {
      fetch(`https://123avatars.com/v2/get-messages/${this.salesRepId}/${this.managerId}`)
        .then(response => response.json())
        .then(data => {
          this.messages = data;
          this.$nextTick(this.scrollToBottom);
        }).catch(err => {
          console.error('error fetching messages: ', err);
        });
    },
    sendMessage() {
      if (this.messageInput.trim() !== '') {
        const senderId = this.userType === 'REP' ? this.salesRepId : this.managerId;
        const message = {
          sales_rep_id: this.salesRepId,
          manager_id: this.managerId,
          sender: senderId,
          text: this.messageInput,
        };

        fetch('https://123avatars.com/v2/send-message', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(message),
        })
          .then(response => response.json())
          .then(() => {
            this.messageInput = '';
            this.$nextTick(this.scrollToBottom);
          });
      }
    },
    scrollToBottom() {
      const chatMessages = this.$refs.chatMessages;
      chatMessages.scrollTop = chatMessages.scrollHeight;
    },
    getMessageClass(message) {
      const senderId = this.userType === 'REP' ? this.salesRepId : this.managerId;
      return message.sender === senderId ? 'message-user' : 'message-manager';
    },
    getMessageSender(message) {
      if (this.userType === 'REP') {
        return message.sender === this.salesRepId ? 'From: Me' : 'From: Manager';
      } else if (this.userType === 'MANAGER') {
        return message.sender === this.managerId ? 'From: Me' : 'From: Sales Rep';
      }
    }
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

.chat-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  padding: 20px;
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  position: relative;
}

.message-manager {
  background-color: #007bff;
  color: #fff;
  align-self: flex-start;
}

.message-user {
  background-color: #e0e0e0;
  align-self: flex-end;
}

.message .sender {
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.message.missed {
  border: 2px solid #ff0000;
  background-color: #ffcccc;
}

.chat-input {
  display: flex;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
</style>