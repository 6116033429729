<template>
    <div class="container-custom mt-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h2>Calls Report</h2>
        <div>
          <div class="text-right mb-2"><strong>Total Leads: {{ totalCount }}</strong></div>
          <div class="d-flex flex-column">
            <div id="customGPT"></div>
          </div>
        </div>
      </div>
      <div v-if="!dialerActive">
        <div class="card-body default-according style-1">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="div" class="bg-secondary">
              <h5 class="mb-0">
                <b-button v-b-toggle.primary_icon_open_close_1>
                  <feather type="bar-chart-2"></feather> Filters
                </b-button>
              </h5>
            </b-card-header>
            <b-collapse
              id="primary_icon_open_close_1"
              accordion="my-accordion-icon-open-close"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div class="row mb-3">
                    <div class="col-md-4 mb-2">
                      <label for="dateFilter">Filter by Date:</label>
                      <select id="dateFilter" v-model="dateFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="All">All</option>
                        <option value="Today">Today</option>
                        <option value="Yesterday">Yesterday</option>
                        <option value="This Week">This Week</option>
                        <option value="Last Week">Last Week</option>
                        <option value="This Month">This Month</option>
                        <option value="Last Month">Last Month</option>
                        <option value="Last 7 Days">Last 7 Days</option>
                        <option value="Last 30 Days">Last 30 Days</option>
                        <option value="Last 90 Days">Last 90 Days</option>
                        <option value="YTD">YTD</option>
                        <option value="Custom">Custom</option>
                      </select>
                      <div class="d-flex mt-2" v-if="dateFilter === 'Custom'">
                        <DatePicker v-model="customStartDate" placeholder="Start Date" class="form-control form-control-sm mr-2" />
                        <DatePicker v-model="customEndDate" placeholder="End Date" class="form-control form-control-sm mr-2" />
                        <button @click="fetchLeads" class="btn btn-success btn-sm">Apply</button>
                      </div>
                    </div>
                    <!-- <div class="col-md-4 mb-2">
                      <label for="typeFilter">Filter by Type:</label>
                      <select id="typeFilter" v-model="typeFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="All">All</option>
                        <option value="Inbound All">Inbound All</option>
                        <option value="Outbound Answers">Outbound Answers</option>
                        <option value="Inbound Caller ID Callbacks">Inbound Caller ID Callbacks</option>
                        <option value="Inbound Voicemail Callbacks">Inbound Voicemail Callbacks</option>
                        <option value="Transferred from Avatar">Transferred from Avatar</option>
                        <option value="Main # Call Ins">Main # Call Ins</option>
                      </select>
                    </div> -->
                    <div class="col-md-4 mb-2">
                      <label for="timezoneFilter">Filter by Timezone:</label>
                      <select id="timezoneFilter" v-model="timezoneFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="All">All</option>
                        <option value="PST">PST</option>
                        <option value="MST">MST</option>
                        <option value="CST">CST</option>
                        <option value="EST">EST</option>
                        <option value="AKST">AKST</option>
                        <option value="HST">HST</option>
                      </select>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="typeFilter">Filter by Duration:</label>
                      <select id="typeFilter" v-model="durationFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="All">All</option>
                        <option value="2-min">2 Min</option>
                        <option value="5-min">5 Min</option>
                        <option value="10-min">10 Min</option>
                        <option value="15-min">15 Min</option>
                        <option value="20-min">20 Min</option>
                        <option value="30-min">30 Min</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-4 mb-2">
                      <label>Search Leads</label>
                      <input
                        type="text"
                        v-model="searchQuery"
                        @keyup="fetchLeads"
                        class="form-control form-control-sm"
                        placeholder="Search by Company Name, Phone Number, Tags"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="shelfLifeFilter">Filter by Shelf Life:</label>
                      <select id="shelfLifeFilter" v-model="shelfLifeFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="All">All</option>
                        <option value="1 Day">1 Day</option>
                        <option value="Under 30 Days">Under 30 Days</option>
                        <option value="Over 30 Days">Over 30 Days</option>
                        <option value="Over 90 Days">Over 90 Days</option>
                        <option value="Over 180 Days">Over 180 Days</option>
                        <option value="Over 365 Days">Over 365 Days</option>
                      </select>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="hoursFilter">Filter by Hours:</label>
                      <select id="hoursFilter" v-model="hoursFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="Show Only During Hours">Show Only During Hours</option>
                        <option value="Show After Hours">Show After Hours</option>
                      </select>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="toFilter">Filter by TOs:</label>
                      <select id="toFilter" v-model="toFilter" @change="fetchLeads" class="form-control form-control-sm">
                        <option value="All">All</option>
                        <option value="Missed">Missed TOs</option>
                        <option value="Requested">Requested TOs</option>
                        <option value="Answered">Answered TOs</option>
                        <option value="Completed">Completed TOs</option>
                      </select>
                    </div>
                  </div>
                  <div class="btn-group mb-3 w-100" role="group">
                    <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('All')">All</button>
                    <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('Never Contacted')">Never Contacted</button>
                    <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('Spoke to Prospect')">Spoke to Prospect</button>
                    <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('Wants to Signup')">Wants to Signup</button>
                    <button type="button" class="btn btn-primary" @click="filterByMilestone('Starred')">Starred</button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover text-center">
          <thead class="thead-light">
            <tr>
              <th>Milestone</th>
              <th>Sales Rep</th>
              <th @click="sortTable('last_call_date')">Last Call Date</th>
              <th @click="sortTable('last_call_time')">Last Call Time</th>
              <th @click="sortTable('last_call_length')">Last Call Length</th>
              <th>Play</th>
              <th @click="sortTable('click_date')">Click Date</th>
              <th @click="sortTable('time')">Time</th>
              <th @click="sortTable('lead_type')">Lead Type</th>
              <th @click="sortTable('company_name')">Company Name</th>
              <th @click="sortTable('state')">State</th>
              <th @click="sortTable('phone')">Phone</th>
              <th @click="sortTable('timezone')">Timezone</th>
              <th @click="sortTable('shelf_life')">Shelf Life (Days)</th>
              <th @click="sortTable('local_time')">Local Time</th>
              <th @click="sortTable('total_talk_time')">Total Talk Time</th>
              <th @click="sortTable('texts')">Texts</th>
              <th @click="sortTable('calls')">Calls</th>
              <th>Google Search</th>
              <th>Tags</th>
              <th>Messages</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in filteredRows" :key="index" :class="index == 0 && dialerActive ? 'current-call' : ''">
              <td>
                <select v-model="row.milestone" @change="updateLead(row)" class="form-control form-control-sm">
                  <option value="Never Contacted">Never Contacted</option>
                  <option value="Spoke to Prospect">Spoke to Prospect</option>
                  <option value="Wants to Signup">Wants to Signup</option>
                  <option value="Starred">Starred</option>
                  <option value="Blacklist">Blacklist</option>
                  <option value="Signed Up">Signed Up</option>
                </select>
              </td>
              <td>{{ row.sales_rep }}</td>
              <td>{{ convertToLocalTime(row.last_call_date) | dateFormat('MM.DD.YYYY') }}</td>
              <td>{{ convertToLocalTimeFormatted(row.start_time) }}</td>
              <td>{{ convertSecondsToMinutes(row.duration) }}</td>
              <td>
                <button class="play-button" @click="toggleAudio(row)">
                  {{ playingCallId === row.id ? 'Pause' : 'Play' }}
                </button>
              </td>
              <td>{{ convertToLocalTime(row.click_date) | dateFormat('MM.DD.YYYY') }}</td>
              <td>{{ convertToLocalTimeFormatted(row.time) }}</td>
              <td>{{ row.type }}</td>
              <td>{{ row.company_name }}</td>
              <td>{{ row.state }}</td>
              <td><a :href="'tel:' + row.phone">{{ formatPhoneNumber(row.phone) }}</a></td>
              <td>{{ row.timezone }}</td>
              <td>{{ row.shelf_life }}</td>
              <td>{{ getLocalTime(row.click_date, row.timezone) }}</td>
              <td>{{ convertSecondsToMinutes(row.total_talk_time) }}</td>
              <td>{{ row.texts }}</td>
              <td>{{ row.calls }}</td>
              <td>
                <button class="btn btn-primary btn-sm" @click="searchProspectCompany(row.company_name, row.state, row.phone)">Search</button>
              </td>
              <td>
                <ul class="list-inline">
                  <li v-for="(tag, tagIndex) in row.tags" :key="tagIndex" class="badge badge-primary mr-1 mb-1">
                    {{ tag }}
                    <span class="remove-tag ml-1 cursor-pointer" @click="removeTag(index, tagIndex)">x</span>
                  </li>
                </ul>
                <input v-model="row.newTag" @keypress.enter="addTag(index)" class="form-control form-control-sm mt-1" placeholder="New Tag" />
              </td>
              <td>
                <button class="btn btn-warning btn-sm" @click="openMessagesPopup(row)">Messages</button>
              </td>
              <td>
                <button class="btn btn-primary btn-sm" @click="openNotesPopup(row.company_name, row.lead_id)">Notes</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <nav>
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
            </li>
            <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: page === currentPage }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <div>Showing {{ filteredRows.length }} of {{ totalCount }} leads</div>
        <div>
          <label for="rowsPerPage">Rows per page:</label>
          <select id="rowsPerPage" v-model="rowsPerPage" @change="fetchLeads" class="form-control form-control-sm d-inline-block w-auto ml-2">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
  
      <div v-if="showOverlay" class="overlay" @click="closePopup"></div>
      <div v-if="showNotesPopup" class="modal fade show d-block" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ notesCompanyName }}</h5>
              <button type="button" class="close" @click="closePopup"><span>&times;</span></button>
            </div>
            <div class="modal-body">
              <table class="table table-bordered text-center table-sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Note</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(note, index) in currentNotes" :key="index">
                    <td>{{ note.date }}</td>
                    <td>{{ note.time }}</td>
                    <td>{{ note.text }}</td>
                    <td><button @click="deleteNote(index, note.id)" class="btn btn-danger btn-sm">Delete</button></td>
                  </tr>
                </tbody>
              </table>
              <textarea v-model="newNoteText" placeholder="Add a new note" class="form-control mb-3 form-control-sm"></textarea>
            </div>
            <div class="modal-footer">
              <button @click="saveNote" class="btn btn-success btn-sm">Save</button>
              <button @click="closePopup" class="btn btn-secondary btn-sm">Close</button>
            </div>
          </div>
        </div>
      </div>
  
      <div v-if="showMessagesPopup" class="modal show d-block" tabindex="-1" style="width:70%;height:50%;">
        <div v-if="showMessagesPopup" class="modal show d-block" tabindex="-1" style="display: flex; justify-content: center; align-items: center;">
          <div class="modal-dialog" style="width: 70%; height: 50%;">
            <div class="modal-content rounded-container" v-if="messages">
              <div class="modal-header">
                <h2 class="modal-title">Messages</h2>
                <button type="button" class="btn-close" aria-label="Close" @click="closeMessagesPopup"></button>
              </div>
              <div class="modal-body container-body">
                <div id="convo" class="conversation">
                  <div v-for="message in messages" :key="message.id" :class="{'bubble-row right': isFromSalesRep(message), 'bubble-row left': !isFromSalesRep(message)}">
                    <div class="bubble-container">
                      <div class="bubble">
                        <span>{{ message.text }}</span>
                        <svg class="tail" width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path v-if="isFromSalesRep(message)" d="M26.5 24.5L12 0.5L0.5 15.5L26.5 24.5Z" fill="#F0F0F0"/>
                          <path v-else d="M0 24.5L14.5 0.5L26 15.5L0 24.5Z" fill="#579AFF"/>
                        </svg>
                      </div>
                      <div class="subtitle bold">{{ getSenderName(message) }}</div>
                      <div class="subtitle">{{ formatDate(message.time) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <!-- Optional footer content -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { TelnyxDevice } from '@telnyx/rtc-sipjs';
  import AuthService from '../../_services/AuthService.js';
  
  export default {
    components: {
      DatePicker
    },
    props: {
      salesRepId: String,
      toCallsFilter: String
    },
    data() {
      return {
        stats: {
          today: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
          thisWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
          lastWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
          sales: { talkTime: 0, totalCalls: 0, inboundCalls: 0, outboundCalls: 0 }
        },
        searchQuery: '',
        dateFilter: 'All',
        typeFilter: 'All',
        milestoneFilter: 'All',
        timezoneFilter: 'All',
        salesRepFilter: 'All',
        shelfLifeFilter: 'All',
        hoursFilter: 'Show Only During Hours',
        durationFilter: 'All',
        toFilter: 'All',
        customStartDate: '',
        customEndDate: '',
        rowsPerPage: 50,
        currentPage: 1,
        leads: [],
        totalRows: 0,
        sortDirection: 'asc',
        showOverlay: false,
        showNotesPopup: false,
        showMessagesPopup: false,
        notesCompanyName: '',
        messagesCompanyName: '',
        currentNotes: [],
        newNoteText: '',
        notesData: {},
        filteredRows: [],
        currentLeadId: null,
        totalCount: 0,
        scriptLink: '',
        signupLink: '',
        qaLink: '',
        trainingLockerLink: '',
        dialerActive: false,
        dialerText: 'Start Dialer',
        playingCallId: null,
        audio: null,
        outboundHuman: false,
        messages: []
      };
    },
    computed: {
      visiblePages() {
        const pages = [];
        let startPage = Math.max(this.currentPage - 2, 1);
        let endPage = Math.min(startPage + 4, this.totalPages);
  
        if (endPage - startPage < 4) {
          startPage = Math.max(endPage - 4, 1);
        }
  
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
  
        return pages;
      },
      _filteredRows() {
        let filtered = this.leads.filter(lead => {
          if (this.searchQuery && !this.matchSearchQuery(lead)) {
            return false;
          }
          if (this.typeFilter !== 'All') {
            if(this.typeFilter == 'Inbound All') {
              if(lead.type != 'Inbound Caller ID Callbacks' && lead.type != 'Inbound Voicemail Callbacks') {
                return false;
              }
            } else if (lead.type !== this.typeFilter) {
              return false;
            }
          }
          if (this.milestoneFilter !== 'All' && lead.milestone !== this.milestoneFilter) {
            return false;
          }
          if (this.timezoneFilter !== 'All' && lead.timezone !== this.timezoneFilter) {
            return false;
          }
          if (this.salesRepFilter !== 'All' && lead.sales_rep !== this.salesRepFilter) {
            return false;
          }
          console.log('uuid: ', lead.uuid);
          console.log('salesRepId: ', this.salesRepId);
          if(this.salesRepId && lead.uuid !== this.salesRepId) {
            return false;
          }
          if (this.shelfLifeFilter !== 'All' && !this.matchShelfLifeFilter(lead.shelf_life)) {
            return false;
          }
          if(this.durationFilter !== 'All' && !this.matchDurationFilter(lead.duration)) {
            return false;
          }
          // if (this.hoursFilter === 'Show Only During Hours' && !this.isDuringBusinessHours(lead.timezone)) {
          //   return false;
          // }
          if (this.hoursFilter === 'Show After Hours' && this.isDuringBusinessHours(lead.timezone)) {
            return false;
          }

          if(this.toFilter !== 'All' && !this.matchToFilter(lead)) {
            return false;
          }

          if(this.dateFilter == 'All') {
            return true;
          } else {
            if (!this.matchDateFilter(lead.last_call_date)) {
            return false;
          }
          }
          return true;
        });
        this.totalRows = filtered.length;
        let sliced = filtered.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage);
        this.filteredRows = filtered;
        return sliced;
      },
      totalPages() {
        return Math.ceil(this.totalCount / this.rowsPerPage);
      },
    },
    methods: {
      filteredLeadRows() {
        let filtered = this.leads.filter(lead => {
          if (this.searchQuery && !this.matchSearchQuery(lead)) {
            return false;
          }
          if (this.typeFilter !== 'All') {
            if(this.typeFilter == 'Inbound All') {
              if(lead.type != 'Inbound Caller ID Callbacks' && lead.type != 'Inbound Voicemail Callbacks') {
                return false;
              }
            } else if (lead.type !== this.typeFilter) {
              return false;
            }
          }
          if (this.milestoneFilter !== 'All' && lead.milestone !== this.milestoneFilter) {
            return false;
          }
          if (this.timezoneFilter !== 'All' && lead.timezone !== this.timezoneFilter) {
            return false;
          }
          if (this.salesRepFilter !== 'All' && lead.sales_rep !== this.salesRepFilter) {
            return false;
          }
          if(this.salesRepId && lead.uuid !== this.salesRepId) {
            return false;
          }
          if (this.shelfLifeFilter !== 'All' && !this.matchShelfLifeFilter(lead.shelf_life)) {
            return false;
          }
          if(this.durationFilter !== 'All' && !this.matchDurationFilter(lead.duration)) {
            return false;
          }
          // if (this.hoursFilter === 'Show Only During Hours' && !this.isDuringBusinessHours(lead.timezone)) {
          //   return false;
          // }
          if (this.hoursFilter === 'Show After Hours' && this.isDuringBusinessHours(lead.timezone)) {
            return false;
          }

          if(this.toFilter !== 'All' && !this.matchToFilter(lead)) {
            return false;
          }

          if(this.dateFilter == 'All') {
            return true;
          } else {
            if (!this.matchDateFilter(lead.last_call_date)) {
            return false;
          }
          }
          return true;
        });
        this.totalRows = filtered.length;
        let sliced = filtered.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage);
        this.filteredRows = filtered;
        return sliced;
      },
      closeMessagesPopup() {
        this.showMessagesPopup = false;
      },
      isFromSalesRep(message) {
        return message.inbound === "0"; // Assuming inbound "0" means from sales rep
      },
      getSenderName(message) {
        return this.isFromSalesRep(message) ? 'You' : 'ABC Plumbing'; // Replace 'ABC Plumbing' with appropriate logic
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-US', options);
      },
      async loadExternalScript() {
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', 'https://cdn.customgpt.ai/js/chat.js')
        // document.head.appendChild(recaptchaScript)
        // recaptchaScript.onload = async () => {
        //   await CustomGPT.init({p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2"});
        //   CustomGPT.hide();
        // }
      },
      convertToLocalTime(utcDateTime) {
        var local = new Date(utcDateTime);
        return local;
      },
      convertToLocalTimeFormatted(utcDateTime) {
        const local = new Date(utcDateTime);
        let hours = local.getHours();
        const minutes = local.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutesStr} ${ampm}`;
      },
      async nextProspect() {
        try {
          let userId = '123009';
          let dialerId = this.$route.query.id;
          const response = await axios.post('https://123avatars.com/v2/next-prospect', {
              user_id: userId,
              id: dialerId
            });
        } catch (err) {
          console.error("Error calling next prospect.", err);
        }
      },
      toggleAudio(row) {
        if (this.playingCallId === row.id) {
          this.audio.pause();
          this.audio.currentTime = 0;
          this.audio = null;
          this.playingCallId = null;
        } else {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          let url = row.recordingUrl;
          if (url) {
            this.audio = new Audio(url);
            this.audio.play();
            this.playingCallId = row.id;
            this.audio.onended = () => {
              this.audio = null;
              this.playingCallId = null;
            };
          } else {  
            alert("No audio URL available");
          }
        }
      },
      convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min${minutes !== 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''}`;
      },
      async startDialer() {
        try {
          if(this.dialerActive) {
            this.dialerActive = false;
            this.dialerText = 'Start Dialer';
            let userId = '123009';
            let dialerId = this.$route.query.id;
            let timezone = this.getTimezone();
            const response = await axios.post('https://123avatars.com/v2/logout-dialer', {
              user_id: userId,
              id: dialerId,
              timezone: timezone
            });
          } else {
            this.dialerText = 'Stop Dialer';
            let userId = '123009';
            let dialerId = this.$route.query.id;
            let timezone = this.getTimezone();
            if (!userId) {
              throw new Error('User ID not found in localStorage');
            }
            const response = await axios.post('https://123avatars.com/v2/login-dialer', {
              user_id: userId,
              id: dialerId,
              timezone: timezone
            });
            this.dialerActive = true;
            console.log('Login successful:', response.data);
          }
        } catch (error) {
          console.error('Error logging in to dialer:', error);
        }
      },
      getUserRoleFromToken() {
        // Get the JWT from local storage
        const token = localStorage.getItem("userToken");

        if (!token) {
          return null; // Return null if no token is found
        }

        // Decode the JWT (JWTs are Base64URL encoded)
        const payload = JSON.parse(atob(token.split('.')[1]));

        // Check if the 'cognito:groups' claim exists in the payload
        const groups = payload['cognito:groups'];

        if (!groups || groups.length === 0) {
          return null; // Return null if no groups are found
        }

        // Assuming the role is determined by the first group in the array
        return groups[0]; // Or you can adjust this to match your application's logic
      },
      formatPhoneNumber(phoneNumber) {
          phoneNumber = phoneNumber.toString();
          const areaCode = phoneNumber.slice(0, 3);
          const centralOfficeCode = phoneNumber.slice(3, 6);
          const stationCode = phoneNumber.slice(6, 10);
          const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
          return formattedPhoneNumber;
      },
      searchProspectCompany(company_name, state, phone) {
        const url = `https://www.google.com/search?q=${encodeURIComponent(this.formatPhoneNumber(phone))}`;
        window.open(url, '_blank');
      },
      openSignUpPage() {
        window.open(this.signupLink, '_blank');
      },
      openScriptPage() {
        window.open(this.scriptLink, '_blank');
      },
      openQAPage() {
        window.open(this.qaLink, '_blank');
      },
      openTrainingLockerPage() {
        window.open(this.trainingLockerLink, '_blank');
      },
      getCognitoSub() {
        // Get the JWT from local storage
        const token = localStorage.getItem("user");

        if (!token) {
          return null; // Return null if no token is found
        }

        // Decode the JWT (JWTs are Base64URL encoded)
        const payload = JSON.parse(atob(token.split('.')[1]));

        // Return the 'sub' value from the payload
        return payload.sub || null;
      },
      async getTargetBasedOnRole(target) {
        //let target = 'https://123avatars.com/v2/sales-reps';
          let role = await AuthService.getUserRole();
          if(role == 'Admins' && this.$route.path == '/users') {
            //target += '?id=' + this.$route.query.id;
          }
          
          if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
            target += '?managerId=' + this.$route.query.id;
          }
          if(role == 'Managers') {
            target += '?managerId=' + await AuthService.getCognitoSub();
          }
      },
      async fetchLeads(id = null) {
        const params = {
          search: this.searchQuery,
          date: this.dateFilter,
          type: this.typeFilter,
          milestone: this.milestoneFilter,
          timezone: this.timezoneFilter,
          salesRep: this.salesRepFilter,
          salesRepId: this.salesRepId,
          shelfLife: this.shelfLifeFilter,
          hours: this.hoursFilter,
          startDate: this.customStartDate,
          endDate: this.customEndDate,
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currentPage,
          toFilter: this.toFilter
        };
        let role = this.getUserRoleFromToken();
        
        if (!role) {
          id = this.getCognitoSub();
          let target = 'https://123avatars.com/v2/leads';
          let role = await AuthService.getUserRole();
          if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
            target += '?id=' + this.$route.query.id;
            params.id = this.$route.query.id;
          } else if(role == 'Managers') {
            let sub = await AuthService.getCognitoSub();
            target += '?id=' + sub;
            params.id = sub;
          } else {
            let sub = await AuthService.getCognitoSub();
            target += '?id=' + sub;
            params.id = sub;
          }
          //params.id = id;
          params.leadType = 'two-min';
          axios.get('https://123avatars.com/v2/leads', { params })
          .then(response => {
            this.leads = response.data.leads;
            this.totalRows = response.data.total;
            this.totalCount = response.data.total;
            this.scriptLink = response.data.scriptLink;
            this.signupLink = response.data.signupLink;
            this.qaLink = response.data.qaLink;
            this.trainingLockerLink = response.data.trainingLockerLink;
            this.filteredRows = this.filteredLeadRows();
            if(this.dialerActive && this.filteredRows[0].type === 'Outbound Human') {
              this.outboundHuman = true;
            } else {
              this.outboundHuman = false;
            }
            setTimeout(() => {
              this.fetchLeads();
            },15000);
          })
          .catch(error => {
            console.error('Error fetching leads:', error);
            setTimeout(() => {
              this.fetchLeads();
            },15000);
          });
        } else {
          params.id = '8a2e5bf6-1380-4ffb-9316-811951739a99';
          params.leadType = 'two-min';
          axios.get('https://123avatars.com/v2/leads', { params })
          .then(response => {
            this.leads = response.data.leads;
            this.totalRows = response.data.total;
            this.totalCount = response.data.total;
            this.scriptLink = response.data.scriptLink;
            this.signupLink = response.data.signupLink;
            this.qaLink = response.data.qaLink;
            this.trainingLockerLink = response.data.trainingLockerLink;
            this.filteredRows = this.filteredLeadRows();
            if(this.dialerActive && this.filteredRows[0].type === 'Outbound Human') {
              this.outboundHuman = true;
            } else {
              this.outboundHuman = false;
            }
            setTimeout(() => {
              this.fetchLeads();
            },5000);
          })
          .catch(error => {
            console.error('Error fetching leads:', error);
            setTimeout(() => {
              this.fetchLeads();
            },5000);
          });
        }
      },
      fetchStats() {
        let id = this.$route.query.id;
          if(id) {
            const params = {
            uuid: id
          };
          axios.get('https://123avatars.com/v2/stats', { params })
            .then(response => {
              this.stats.today = response.data.today;
              this.stats.thisWeek = response.data.thisWeek;
              this.stats.lastWeek = response.data.lastWeek;
            })
            .catch(error => {
              console.error('Error fetching stats:', error);
            });
          }
      },
      searchTable() {
        this.fetchLeads();
      },
      filterTable() {
        this.fetchLeads();
      },
      filterByMilestone(milestone) {
        this.milestoneFilter = milestone;
        this.fetchLeads();
      },
      applyCustomDateRange() {
        this.fetchLeads();
      },
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
          this.fetchLeads();
        }
      },
      updateRowsPerPage() {
        this.currentPage = 1;
        this.fetchLeads();
      },
      sortTable(property) {
        const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
        this.leads.sort((a, b) => {
          let x = a[property];
          let y = b[property];
          if (property === 'date') {
            x = new Date(a.start_time);
            y = new Date(b.start_time);
          }
          if (direction === 'asc') {
            return x > y ? 1 : x < y ? -1 : 0;
          } else {
            return x < y ? 1 : x > y ? -1 : 0;
          }
        });
        this.sortDirection = direction;
        this.filteredRows = this.filteredLeadRows();
      },
      matchSearchQuery(lead) {
        const query = this.searchQuery.toLowerCase();
        return (
          lead.phone.toLowerCase().includes(query) ||
          lead.company_name.toLowerCase().includes(query) ||
          lead.state.toLowerCase().includes(query) ||
          (lead.tags || []).some(tag => tag.toLowerCase().includes(query))
        );
      },
      matchDateFilter(date) {
        const d = new Date(date);
        const today = new Date();
        switch (this.dateFilter) {
          case 'All':
            return false;
          case 'Today':
            let isSame = this.isSameDay(today, d);
            return this.isSameDay(today, d);
          case 'Yesterday':
            const yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            return this.isSameDay(yesterday, d);
          case 'This Week':
            return this.isSameWeek(today, d);
          case 'Last Week':
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);
            return this.isSameWeek(lastWeek, d);
          case 'This Month':
            return this.isSameMonth(today, d);
          case 'Last Month':
            const lastMonth = new Date();
            lastMonth.setMonth(today.getMonth() - 1);
            return this.isSameMonth(lastMonth, d);
          case 'Last 7 Days':
            const last7Days = new Date();
            last7Days.setDate(today.getDate() - 7);
            return d >= last7Days && d <= today;
          case 'Last 30 Days':
            const last30Days = new Date();
            last30Days.setDate(today.getDate() - 30);
            return d >= last30Days && d <= today;
          case 'Last 90 Days':
            const last90Days = new Date();
            last90Days.setDate(today.getDate() - 90);
            return d >= last90Days && d <= today;
          case 'YTD':
            const startOfYear = new Date(today.getFullYear(), 0, 1);
            return d >= startOfYear && d <= today;
          case 'Custom':
            const startDate = new Date(this.customStartDate);
            const endDate = new Date(this.customEndDate);
            return d >= startDate && d <= endDate;
          default:
            return true;
        }
      },
      isSameDay(date1, date2) {
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      },
      isSameWeek(date1, date2) {
        const startOfWeek1 = new Date(date1);
        startOfWeek1.setDate(date1.getDate() - date1.getDay());
        startOfWeek1.setHours(0, 0, 0, 0);
        const startOfWeek2 = new Date(date2);
        startOfWeek2.setDate(date2.getDate() - date2.getDay());
        startOfWeek2.setHours(0, 0, 0, 0);
        return startOfWeek1.getTime() === startOfWeek2.getTime();
      },
      isSameMonth(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
      },
      matchShelfLifeFilter(shelfLife) {
        switch (this.shelfLifeFilter) {
          case '1 Day':
            return shelfLife === 1;
          case 'Under 30 Days':
            return shelfLife < 30;
          case 'Over 30 Days':
            return shelfLife > 30;
          case 'Over 90 Days':
            return shelfLife > 90;
          case 'Over 180 Days':
            return shelfLife > 180;
          case 'Over 365 Days':
            return shelfLife > 365;
          default:
            return true;
        }
      },
      matchDurationFilter(duration) {
        switch(this.durationFilter) {
          case '2-min':
            return duration > 120;
          case '5-min':
            return duration > 300;
          case '10-min':
            return duration > 600;
          case '15-min':
            return duration > 900;
          case '20-min':
            return duration > 1200;
          case '30-min':
            return duration> 1800;
          default:
            true;
        }
      },
      matchToFilter(lead) {
        switch(this.toFilter) {
          case 'Missed':
            return lead.requested_to == 1 && lead.answered_to == 0;
          break;
          case 'Requested':
            return lead.requested_to == 1;
          break;
          case 'Answered':
            return lead.answered_to == 1;
          break;
          case 'Completed':
            return lead.qualified_to == 1;
            break;
          default:
            return false;
        }
      },
      isDuringBusinessHours(timeZone) {
        const timeZoneMapping = {
          EST: 'America/New_York',
          PST: 'America/Los_Angeles',
          CST: 'America/Chicago',
          MST: 'America/Denver',
          HST: 'Pacific/Honolulu',
          AKST: 'America/Anchorage',
        };
        const ianaTimeZone = timeZoneMapping[timeZone];
        if (!ianaTimeZone) {
          throw new Error(`Unrecognized timezone abbreviation: ${timeZone}`);
        }
        const now = new Date();
        const options = {
          timeZone: ianaTimeZone,
          hour: '2-digit',
          hour12: false,
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(now);
        const hour = parseInt(parts.find(p => p.type === 'hour').value, 10);
        return hour >= 9 && hour < 17;
      },
      getLocalTime(date, timeZone) {
        const timeZoneMapping = {
          EST: 'America/New_York',
          PST: 'America/Los_Angeles',
          CST: 'America/Chicago',
          MST: 'America/Denver',
          HST: 'Pacific/Honolulu',
          AKST: 'America/Anchorage',
        };
        const ianaTimeZone = timeZoneMapping[timeZone];
        if (!ianaTimeZone) {
          return new Date(date).toLocaleTimeString();
        }
        return new Date(date).toLocaleTimeString('en-US', { timeZone: ianaTimeZone });
      },
      updateLead(row) {
        axios.put(`https://123avatars.com/v2/leads/${row.sales_lead_id}`, row)
          .then(response => {
            console.log('Lead updated:', response.data);
          })
          .catch(error => {
            console.error('Error updating lead:', error);
          });
      },
      openNotesPopup(companyName, leadId) {
        this.notesCompanyName = `Notes for ${companyName}`;
        this.currentLeadId = leadId;
        this.showOverlay = true;
        this.showNotesPopup = true;
        const userId = localStorage.getItem('userId');
        axios.get(`https://123avatars.com/v2/notes?leadId=${leadId}&userId=${userId}`)
          .then(response => {
            this.currentNotes = response.data.notes;
          })
          .catch(error => {
            console.error('Error fetching notes:', error);
            this.currentNotes = [];
          });
      },
      openMessagesPopup(row) {
        this.messagesCompanyName = `Messages with ${row.company_name}`;
        this.showOverlay = true;
        this.showMessagesPopup = true;
        this.messages = [];
        const avatarId = row.avatar_id;
        const leadId = row.lead_id;
        axios.get(`https://123avatars.com/backend/lead_conversation.php?avatar_id=${row.avatar_id}&lead_id=${row.lead_id}`, {
          params: {
            avatar_id: avatarId,
            lead_id: leadId
          }
        })
        .then(response => {
          this.messages = response.data;
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
      },
      closePopup() {
        this.showOverlay = false;
        this.showNotesPopup = false;
        this.showMessagesPopup = false;
        this.currentLeadId = null;
      },
      saveNote() {
        if (!this.newNoteText.trim()) return;
        const newNote = {
          userId: localStorage.getItem("userId"),
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          text: this.newNoteText.trim(),
        };
        axios.post(`https://123avatars.com/v2/leads/${this.currentLeadId}/notes`, newNote)
          .then(response => {
            this.currentNotes.push(newNote);
            this.newNoteText = '';
            this.fetchLeads();
          })
          .catch(error => {
            console.error('Error saving note:', error);
          });
      },
      deleteNote(index, noteId) {
        axios.delete(`https://123avatars.com/v2/leads/${this.currentLeadId}/notes/${noteId}`)
          .then(response => {
            this.currentNotes.splice(index, 1);
            this.fetchLeads();
          })
          .catch(error => {
            console.error('Error deleting note:', error);
          });
      },
      addTag(rowIndex) {
        const newTag = this.filteredRows[rowIndex].newTag.trim();
        if (newTag) {
          this.filteredRows[rowIndex].tags.push(newTag);
          this.filteredRows[rowIndex].newTag = '';
          this.updateLead(this.filteredRows[rowIndex]);
        }
      },
      removeTag(rowIndex, tagIndex) {
        this.filteredRows[rowIndex].tags.splice(tagIndex, 1);
        this.updateLead(this.filteredRows[rowIndex]);
      },
    },
    mounted() {
      this.loadExternalScript();
      this.toFilter = this.toCallsFilter ? this.toCallsFilter : 'All';
      this.fetchLeads();
      this.fetchStats();
    },
  };
  </script>
  
  <style scoped>
  body {
    background-color: #F4F8FF;
  }
  .container-custom {
    width: 90%;
    margin: auto;
  }
  .stats-section-wrapper {
    width: 60%;
    margin: auto;
  }
  .current-call {
    background-color: lightgreen;
  }
  .custom-table {
    border-color: #000;
  }
  .custom-table th,
  .custom-table td {
    border-color: #000;
  }
  .text-right strong {
    color: #364C6C;
  }
  .btn-success {
    background-color: #579AFF;
    color: #fff;
    border: 2px solid #009aff;
  }
  .btn-success:hover {
    background-color: #fff;
    color: #579AFF;
    border-color: #579AFF;
  }
  .btn-danger {
    background-color: #FF0000;
    color: #fff;
    border: 2px solid #FF0000;
  }
  .btn-danger:hover {
    background-color: #fff;
    color: #FF0000;
    border-color: #FF0000;
  }
  .thead-light {
    background-color: #C5DBFF;
  }
  .badge-primary {
    background-color: #364C6C;
  }
  .alert-primary {
    background-color: #579AFF;
    color: white;
  }
  .alert-secondary {
    background-color: #F0F0F0;
    color: black;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .modal-header {
    background-color: #C5DBFF;
    border: 0;
  }
  body {
  background-color: #F4F8FF;
}

header {
  background-color: #579aff;
  padding: 20px;
  color: #fff;
}
header h1 {
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
}
header .dialer {
  margin: 0 30px;
}
header .dialer .btn {
  color: #fff;
  border: 2px solid #fff;
  font-weight: bold;
}
header .dialer .btn:hover {
  color: #59f;
  background-color: #fff;
}
header .dialer .dot-outer {
  margin: 0 15px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}
header .dialer .dot-inner {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}
header .dialer .dot-inner.off {
  background-color: #FF0000;
}
header .dialer .dot-inner.on {
  background-color: #00FF0A;
}
header .dialer .dot-inner.dialing {
  background-color: #FFF500;
}
header .buttons .btn {
  padding: 10px;
  position: relative;
  color: #fff;
  border: 2px solid #fff;
  height: 40px;
  margin-left: 20px;
}
header .buttons .btn svg {
  fill: #fff;
}
header .buttons .btn:hover {
  background-color: #fff;
}
header .buttons .btn:hover svg {
  fill: #59f;
}
header .buttons .btn .badge {
  position: absolute;
  top: -13px;
  right: -15px;
  background-color: #ff0000;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  vertical-align: sub;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 0;
}

main .sidebar {
  padding: 0;
  color: #fff;
  background-color: #364C6C;
  font-weight: bold;
}
main .sidebar .side-nav {
  display: block;
  padding: 15px 20px;
  color: #fff;
  text-decoration: none;
}
main .sidebar .side-nav:hover {
  background-color: #263750;
}
main .sidebar .side-nav.active {
  background-color: #627CA3;
}
main .statssales {
  background-color: #364C6C;
  border-radius: 40px 0 0 40px;
  padding: 15px 15px 1px 15px;
  margin-top: 10px;
  line-height: 1.3rem;
}
main .statssales .inner-box {
  margin-bottom: 30px;
}
main .content {
  padding: 10px;
}
main .content .btn {
  background-color: #579AFF;
  color: #fff;
  margin: 10px;
  border: 2px solid #009aff;
}
main .content .btn:hover {
  background-color: #fff;
  color: #579AFF;
  border-color: #579AFF;
  margin: 10px;
}
main .rounded-container {
  margin: 10px;
  background-color: #fff;
  border-radius: 20px;
  background-clip: border-box;
}
main .rounded-container .btn,
main .rounded-container .btn:hover {
  margin: 0;
}
main .rounded-container .bold {
  font-weight: bold;
}
main .rounded-container h2 {
  background-color: #C5DBFF;
  padding: 20px;
  font-size: 1.5rem;
  border-radius: 20px 20px 0 0;
}
main .rounded-container h3 {
  background-color: #C5DBFF;
  padding: 20px;
  font-size: 1.5rem;
  border-radius: 20px 20px 0 0;
  font-weight: bold;
}
main .rounded-container .container-body {
  padding: 10px 20px 20px;
}
main footer {
  padding: 10px;
}

.notification-content {
  text-align: center;
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.modal .modal-header {
  background-color: #C5DBFF !important;
  border: 0;
}

#dialer_table {
  width: 100%;
  font-size: 1.5em;
}

#dialer_table tr td {
  text-align: center;
  height: 50px;
  width: 33%;
}

#dialer_table #dialer_input_td {
  border-bottom: 1px solid #fafafa;
}

#dialer_table #dialer_input_td input {
  width: 100%;
  border: none;
  font-size: 1.6em;
}

/* Remove arrows from type number input : Chrome, Safari, Edge, Opera */
#dialer_table #dialer_input_td input::-webkit-outer-spin-button,
#dialer_table #dialer_input_td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows from type number input : Firefox */
#dialer_table #dialer_input_td input[type=number] {
  -moz-appearance: textfield;
}

#dialer_table #dialer_input_td input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc;
  opacity: 1; /* Firefox */
}

#dialer_table #dialer_input_td input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #cccccc;
}

#dialer_table #dialer_input_td input::-ms-input-placeholder { /* Microsoft Edge */
  color: #cccccc;
}

#dialer_table #dialer_call_btn_td {
  color: #ffffff;
  background-color: #579aff;
  border: solid 2px #579aff;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  padding: 5px 32px;
  text-align: center;
  display: inline-block;
  margin: 10px 2px 4px 2px;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  --webkit-transition: all 300ms ease;
}

#dialer_table #dialer_call_btn_td:hover {
  background-color: #ffffff;
  color: #579aff;
}

#dialer_table .dialer_num_tr td {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#dialer_table .dialer_num_tr td:nth-child(1) {
  border-right: 1px solid #fafafa;
}

#dialer_table .dialer_num_tr td:nth-child(3) {
  border-left: 1px solid #fafafa;
}

#dialer_table .dialer_num_tr:nth-child(1) td,
#dialer_table .dialer_num_tr:nth-child(2) td,
#dialer_table .dialer_num_tr:nth-child(3) td,
#dialer_table .dialer_num_tr:nth-child(4) td {
  border-bottom: 1px solid #fafafa;
}

#dialer_table .dialer_num_tr .dialer_num {
  color: #000000;
  cursor: pointer;
}

#dialer_table .dialer_num_tr .dialer_num:hover {
  background-color: #579aff;
  color: #fff;
}

#dialer_table .dialer_num_tr:nth-child(0) td {
  border-top: 1px solid #fafafa;
}

#dialer_table .dialer_del_td img {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050; /* Bootstrap default modal z-index */
}

.modal-centered {
  position: relative;
}

.conversation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  max-height: 500px;
  overflow-y: auto;
}
.bubble-row {
  display: flex;
  align-items: flex-end;
}
.bubble-row.right {
  justify-content: flex-end;
}
.bubble-row.left {
  justify-content: flex-start;
}
.bubble-container {
  max-width: 60%;
  padding: 10px;
  border-radius: 20px;
  position: relative;
}
.bubble-container .bubble {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #579AFF;
  color: white;
}
.bubble-container .bubble svg.tail {
  position: absolute;
  bottom: -5px;
}
.bubble-container .bubble svg.tail path {
  fill: white;
}
.bubble-container .subtitle {
  margin-top: 5px;
  font-size: 0.8em;
}
.bubble-container .subtitle.bold {
  font-weight: bold;
}
.bubble-container.right .bubble {
  background-color: #F0F0F0;
  color: black;
}
.bubble-container.right .bubble svg.tail path {
  fill: #F0F0F0;
}
.bubble-container.left .bubble {
  background-color: #579AFF;
  color: white;
}
.bubble-container.left .bubble svg.tail path {
  fill: #579AFF;
}

.active-div {
  /* Styles for active div */
  background-color: green;
}

.paused-div {
  /* Styles for paused div */
  opacity: 0.5;
}

.google-icon {
  display: inline-block;
  margin-right: 0.5rem;
}
  </style>