<template>
  <div style="width:80%; margin-left:10%; margin-top:5%;">
    <div class="inner-box">
      <div class="rounded-container">
        <h3>Daily SPIFs</h3>
        <div class="container-body">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>TO #</th>
                <th>For That TO</th>
                <th>Total Spiffs Paid Out Today</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr
                v-for="(row, index) in spiffData"
                :key="index"
                :class="{'highlight-green': row.isBonus, 'highlight-blue': row.isCurrentTO}"
              >
                <td>{{ row.to }}</td>
                <td>{{ formatCurrency(row.amount) }}</td>
                <td>{{ formatCurrency(row.total) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import AuthService from '../../_services/AuthService.js';
  import axios from 'axios';

  export default {
    props: {
    totalTOsCompleted: Number
  },
    data() {
      return {
        spiffData: [
          { to: '1st', amount: 50, total: 50, isBonus: false, isCurrentTO: false },
          { to: '2nd', amount: 50, total: 100, isBonus: false, isCurrentTO: false },
          { to: '3rd', amount: 50, total: 150, isBonus: false, isCurrentTO: false },
          { to: '4th', amount: 50, total: 200, isBonus: false, isCurrentTO: false },
          { to: '5th', amount: 500, total: 700, isBonus: true, isCurrentTO: false },
          { to: '6th', amount: 100, total: 800, isBonus: false, isCurrentTO: false },
          { to: '7th', amount: 100, total: 900, isBonus: false, isCurrentTO: false },
          { to: '8th', amount: 100, total: 1000, isBonus: false, isCurrentTO: true },
          { to: '9th', amount: 100, total: 1100, isBonus: false, isCurrentTO: false },
          { to: '10th', amount: 1000, total: 2100, isBonus: true, isCurrentTO: false },
          { to: '11th', amount: 100, total: 2200, isBonus: false, isCurrentTO: false },
          { to: '12th', amount: 100, total: 2300, isBonus: false, isCurrentTO: false },
          { to: '13th', amount: 100, total: 2400, isBonus: false, isCurrentTO: false },
          { to: '14th', amount: 100, total: 2500, isBonus: false, isCurrentTO: false },
          { to: '15th', amount: 1500, total: 4000, isBonus: true, isCurrentTO: false },
          { to: '16th', amount: 100, total: 4100, isBonus: false, isCurrentTO: false },
          { to: '17th', amount: 100, total: 4200, isBonus: false, isCurrentTO: false },
          { to: '18th', amount: 100, total: 4300, isBonus: false, isCurrentTO: false },
          { to: '19th', amount: 100, total: 4400, isBonus: false, isCurrentTO: false },
          { to: '20th', amount: 2000, total: 6400, isBonus: true, isCurrentTO: false }
        ],
        role: '',
        totalTOs: 0
      };
    },
    async mounted() {
      this.totalTOs = this.totalTOsCompleted;

      // Get the user's role
      this.role = await AuthService.getUserRole();

      // If the user is an admin, fetch the total completed TOs for today
      if (this.role === 'Admins') {
        try {
          // Make the API call using axios to fetch completed TOs for the current day
          const response = await axios.get('https://123avatars.com/v2/scoreboard-stats');  // Replace with your actual API endpoint

          // Assuming the backend returns { totalCompletedTOs: number }
          this.totalTOs = response.data.totalCompletedTOs || 0;
          console.log('totalTOs: ', this.totalTOs);
        } catch (error) {
          console.error('Failed to fetch completed TOs:', error);
        }
      } 

      // Update the current TO highlight after fetching the data
      this.updateCurrentTOHighlight();
    },
    methods: {
      formatCurrency(amount) {
        return `₱${amount.toLocaleString()}`;
      },
      updateCurrentTOHighlight() {
        this.spiffData.forEach((row, index) => {
          row.isCurrentTO = (index + 1 === this.totalTOs);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table th,
  .table td {
    text-align: center;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .highlight-green {
    background-color: #00ff00; /* Green highlight for bonus */
  }
  
  .highlight-blue {
    background-color: #00bfff; /* Blue highlight for current TO */
  }
  </style>
  